const env = process.env.REACT_APP_ENV ?? "development";


export const BASE_URL = env === "production" ? "https://api.fcahpt.site" : "https://staging.fcahpt.site";

export   const departmentOptions = [
    'Animal Health ND',
    'Computer Science',
    'Veterinary Laboratory Technology',
    'PRM',
    'Agric Business',
    'Science Laboratory Technology HND',
    'Statistics',
    'Fisheries',
    'Agricultural Extension',
    'Science Laboratory Technology ND',
    'Animal Health HND',
    'Animal Production',
    'General Studies',
  ];

  export const departmentOptionsLecturer = [
    'Animal Health ND',
    'Computer Science',
    'Veterinary Laboratory Technology',
    'PRM',
    'Agric Business',
    'HNDSLT ENV',
    'HNDSLT CHEM',
    'HNDSLT Physics',
    'Microbiology(HND)',
    'Statistics',
    'Fisheries',
    'AEM',
    'Science Laboratory Technology ND',
    'Animal Health HND',
    'Animal Production',
    'General Studies',
  ];